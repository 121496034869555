.tiptap {
    min-height: 80vh;
    outline: none;
}

.tiptap .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
}

.Editor {
    max-width: 960px;
    padding: 24px 24px;
    margin: 0 auto;
    position: relative;
}


p {
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
}