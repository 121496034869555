.SignInButton {
  border: 1px solid #ccc;
  border-radius: 4px;
  background: none;
  padding: 16px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background: #eee;
  }
}