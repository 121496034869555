.Root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: #f8f8f9;
}

.Header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgb(229, 229, 229);
  display: flex;
  align-items: center;
  padding: 0 36px
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.Card {
  border: 1px solid rgb(229, 229, 229);
  border-radius: 4px;
  padding: 16px 24px 48px 24px;
  width: 350px;
  display: flex;
  flex-direction: column;
}
